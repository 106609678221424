@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    color-scheme: light only;
  }

  /* * {
    @apply font-montserrat;
  } */
  html,
  body {
    @apply w-full cursor-default bg-white text-white;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="text"]::-webkit-inner-spin-button,
  input[type="text"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .rec.rec-arrow:disabled {
    visibility: hidden;
  }

  input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  input[type="radio"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
}

@layer utilities {
  .mark {
    border: 1px solid red;
  }

  .header-gradient {
    background: linear-gradient(to right, #462EE11A, #462EE1, #462EE11A);
  }

  .background-dapp {
    background: linear-gradient(180deg, rgba(70, 46, 225, 0.00) 0%, rgba(70, 46, 225, 0.50) 100%), #100A33;
  }

  .nft-data-background {
    background: linear-gradient(180deg, rgba(94, 54, 255, 0.15) 0%, rgba(94, 54, 255, 0.00) 50%, rgba(94, 54, 255, 0.15) 100%);
  }

  .border-unlockd {
    background-image: linear-gradient(to bottom, #462EE1, rgba(70, 46, 225, 0));
    ;
  }

  .border-unlockd-inverted {
    background-image: linear-gradient(to bottom, rgba(70, 46, 225, 0), #462EE1);
    ;
  }

  .scrollbar::-webkit-scrollbar {
    width: 10px;
    height: 20px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    margin: 50px;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #999999;
    border-radius: 100vh;
    border: 3px solid #120D2B;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: white;
  }

  .scrollbar-mynfts::-webkit-scrollbar {
    width: 40px;
    height: 86px;
  }

  .scrollbar-mynfts::-webkit-scrollbar-track {
    border-radius: 100vh;
    margin-top: 18px;
  }

  .scrollbar-mynfts::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 100vh;
    border: 16px solid #120D2B;
  }

  .scrollbar-mynfts::-webkit-scrollbar-thumb:hover {
    background: #c0a0b9;
  }

  .scrollbar-admitted::-webkit-scrollbar {
    width: 40px;
    height: 86px;
  }

  .scrollbar-admitted::-webkit-scrollbar-track {
    border-radius: 100vh;
  }

  .scrollbar-admitted::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 100vh;
    border: 16px solid #120D2B;
  }

  .scrollbar-admitted::-webkit-scrollbar-thumb:hover {
    background: #c0a0b9;
  }

  .scrollbar-selected::-webkit-scrollbar {
    width: 40px;
    height: 86px;
  }

  .scrollbar-selected::-webkit-scrollbar-track {
    border-radius: 100vh;
    margin-top: -12px;
  }

  .scrollbar-selected::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 100vh;
    border: 16px solid #120D2B;
  }

  .scrollbar-selected::-webkit-scrollbar-thumb:hover {
    background: #c0a0b9;
  }

  .scrollbar-bid-history::-webkit-scrollbar {
    width: 10px;
    height: 20px;
  }

  .scrollbar-bid-history::-webkit-scrollbar-track {
    border-radius: 100vh;
    margin: 25px;
  }

  .scrollbar-bid-history::-webkit-scrollbar-thumb {
    background: #999999;
    border-radius: 100vh;
    border: 3px solid #120D2B;
  }

  .scrollbar-bid-history::-webkit-scrollbar-thumb:hover {
    background: white;
  }

  .scrollbar-nfts-checkbox-list::-webkit-scrollbar {
    width: 10px;
    height: 20px;
  }

  .scrollbar-nfts-checkbox-list::-webkit-scrollbar-track {
    border-radius: 100vh;
  }

  .scrollbar-nfts-checkbox-list::-webkit-scrollbar-thumb {
    background: #999999;
    border-radius: 100vh;
    border: 3px solid #120D2B;
  }

  .scrollbar-nfts-checkbox-list::-webkit-scrollbar-thumb:hover {
    background: white;
  }

  .modal {
    @apply h-fit border-glow-sm bg-secondary border-2 rounded-3xl relative;
  }

  .primary-button {
    @apply border-2 active:contrast-125 font-bold rounded-full box-border block text-center break-words transition-colors duration-75 disabled:bg-[#ffffff99] disabled:text-white disabled:border-[#9A9A9A] bg-primary border-primary text-white hover:border-white focus-visible:border-white
  }

  .link-hover {
    @apply transition-colors duration-300 hover:text-gray-300;
  }

  .element-shadow {
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.4);
  }

  .border-glow-sm {
    box-shadow: inset 0 0 6px rgba(237, 222, 255, 1),
      0 0 6px rgba(237, 222, 255, 1);
  }

  .border-glow-lg {
    box-shadow: inset 0 0 6px rgba(237, 222, 255, 1),
      0 0 6px rgba(237, 222, 255, 1), inset 0 0 8px rgba(237, 222, 255, 0.5),
      0 0 8px rgba(237, 222, 255, 0.5), inset 0 0 15px rgba(237, 222, 255, 1),
      0 0 15px rgba(237, 222, 255, 1);
  }

  .border-spacing-table {
    border-spacing: 0px 24px;
  }

  .text-shadow {
    text-shadow: 0 0 10px #fff;
  }

  .border-glow-shadow-lg {
    box-shadow: inset 0 0 6px rgba(237, 222, 255, 1),
      0 0 6px rgba(237, 222, 255, 1), inset 0 0 8px rgba(237, 222, 255, 0.5),
      0 0 8px rgba(237, 222, 255, 0.5), inset 0 0 15px rgba(237, 222, 255, 1),
      0 0 15px rgba(237, 222, 255, 1), 14px -13px 19px #3724ba;
  }

  .button-bg-transition {
    transition-property: background-color;
    transition-duration: 200ms;
  }
}